<template>
  <div class="b_reset-password">
    <header class="b_reset-password__header">
      <div class="container">
        <router-link to="/login" class="redirect_login">
          <img src="@/assets/logo/white.svg" alt="Biobe" name="Biobe" />
        </router-link>
        <div class="send-message">
          <img
            src="@/assets/icons/message.svg"
            alt="Icone de enviar mensagem"
          />
          <a href="mailto:biobe@biobe.com.br">Dúvidas? Fale conosco</a>
        </div>
      </div>
    </header>
    <div class="container b_reset-password__content">
      <div class="form-reset-password">
        <div class="form-reset-password--content">
          <div class="form-reset-password--content_title">
            <img src="@/assets/icons/icon-lock.svg" alt="Ilustração" />
          </div>

          <slot />
        </div>
      </div>
    </div>
    <footer class="b_reset-password__footer">
      © {{ currentYear }} todos direitos reservados
    </footer>
  </div>
</template>

<script>
export default {
  data: () => ({
    currentYear: new Date().getFullYear()
  })
};
</script>
<style lang="scss">
.b_reset-password {
  height: 100vh;
  background-color: var(--v-primary-base);
  display: flex;
  flex-direction: column;

  &__header {
    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .send-message {
        display: flex;
        align-items: center;

        img {
          margin-right: 0.5rem;
        }

        a {
          color: #fff;
          font-size: 0.8rem;
          cursor: pointer;
          font-weight: 500;
          transition: all 300ms;
          text-decoration: none;

          &:hover {
            color: var(--v-secondary-base);
          }
        }
      }
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 75vh;
    max-width: 400px;

    .form-reset-password {
      color: #fff;
      width: 100%;
      text-align: center;

      &--content_subtitle {
        margin-top: 2rem;
        margin-bottom: 1rem;
      }

      &--content_fields {
        .theme--light.v-label {
          color: rgba($color: #fff, $alpha: 0.5);
        }

        .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
          > .v-input__control
          > .v-input__slot {
          background: rgba($color: #fff, $alpha: 0.3);
          box-shadow: none;
        }

        .v-input--has-state.error--text .v-label,
        .v-text-field.v-text-field--solo .v-input__control input {
          color: #fff;
        }

        .v-icon.v-icon.v-icon--link {
          color: rgba($color: #fff, $alpha: 0.5);
        }
      }

      .actions {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &--back-home {
          text-decoration: none;
          color: #fff;
          font-weight: 500;
          cursor: pointer;
          transition: all 300ms;

          &:hover {
            color: var(--v-secondary-base);
          }
        }
      }
    }
  }

  &__footer {
    text-align: center;
    padding: 1rem;
    color: #fff;
    bottom: 0;
    right: 0;
    left: 0;
    position: absolute;
  }
}
</style>
